<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs"/>
    <div class="content-box">
      <el-row class="search-box">
        <el-row class="search-row">
          <el-row class="search-item">
            <label>热词名称：</label>
            <el-input v-model.trim="params.title" placeholder="热词名称"></el-input>
          </el-row>
          <el-button @click="handleQuery(true)" class="bg-gradient" type="primary" icon="el-icon-search">搜索</el-button>
          <el-button @click="handleQuery(false)" icon="el-icon-refresh-right" plain>重置</el-button>
        </el-row>
      </el-row>
      <el-row class="table-box">
        <el-alert class="width-434" title="最多允许添加16个热词" type="warning"></el-alert>
        <el-row class="m-bottom-10" type="flex" justify="space-between">
          <div></div>
          <div class="right-btn">
            <el-button @click="action = 'add'; visible = true" class="bg-gradient">添加热词</el-button>
          </div>
        </el-row>
        <el-table v-loading="loading" :data="tableData" :stripe="true" border fit>
          <el-table-column label="序号">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column prop="title" label="标题"></el-table-column>
          <el-table-column prop="content" label="热词名称"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button @click="handleEdit(scope.row)" type="text">编辑</el-button>
              <el-button v-if="scope.row.sort !== 1" @click="handleMove(scope.row.id, 0)" type="text">上移</el-button>
              <el-button v-if="scope.row.sort !== tableData.length" @click="handleMove(scope.row.id, 1)" type="text">下移</el-button>
              <el-button @click="handleDel(scope.row.id)" type="text">
                <el-link type="danger">删除</el-link>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination :total="total" :page-size="params.limit" @handleSizeChangeSub="changePageNum" @handleCurrentChangeSub="handleCurrPage"/>
      </el-row>
    </div>
    <el-dialog title="添加热词" :visible="visible" width="450px" :before-close="handleClose">
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item label="标题" prop="title">
          <el-input v-model.trim="form.title" class="width-280" placeholder="标题"></el-input>
        </el-form-item>
        <el-form-item label="热词名称" prop="content">
          <el-input v-model="form.content" class="width-280" placeholder="热词名称"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button class="bg-gradient" @click="handleSave">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { getPage, addHotWords, delHotWords, moveHotWords } from "@/api/shop/conf/hotWords";
export default {
  name: 'hotWords',
  data() {
    return {
      crumbs: new Map([
          ['特惠商城'], ['页面配置'], ['搜索页热词']
      ]),
      loading: false,
      visible: false,
      params: {
        title: '',
        limit: 1,
        page: 1,
      },
      total: 0,
      tableData: [],
      form: {
        content: '',
        title: '',
        status: '1'
      },
      rules: {
        title: [{ required: true, message: '标题不能为空！' }],
        content: [{ required: true, message: '内容不能为空！' }]
      },
      action: 'add'
    }
  },
  computed: {
    ...mapState(['hotelInfo'])
  },
  mounted() {
    this.params.limit = sessionStorage.getItem('pageSize') * 1;
    this.getHotSearch()
  },
  methods: {
    // 获取热门搜索词列表
    getHotSearch() {
      const params = { ...this.params, hotelId: this.hotelInfo.id };
      // if (this.hotelInfo.hotelName === '达易住特惠') delete params.hotelId
      getPage(params).then(({ success, records, total }) => {
        if (!success) return
        this.loading = false
        this.total = total
        this.tableData = records
      })
    },
    // 改变每页数
    changePageNum(num) {
      this.params.limit = num
      this.getHotSearch()
    },
    // 改变当前页
    handleCurrPage(num) {
      this.params.page = num
      this.getHotSearch()
    },
    // 搜索热词
    handleQuery(bool) {
      this.params.page = 1
      if (bool) return this.getHotSearch()
      this.params.title = ''
    },
    // 编辑热词
    handleEdit({id, content, title}) {
      this.visible = true
      this.action = 'edit'
      this.id = id
      this.form.title = title
      this.form.content = content
    },
    // 保存热词
    handleSave() {
      this.$refs.form.validate(valid => {
        if (!valid) return
        const params = {
          ...this.form,
          hotelId: this.hotelInfo.id,
          companyId: this.hotelInfo.storeId
        }
        if (this.action === 'edit') params.id = this.id
        addHotWords(params, this.action).then(({ success }) => {
          if (!success) return
          this.getHotSearch()
          this.handleClose()
          this.$message({ message: '保存成功！', type: 'success' })
        })
      })
    },
    // 关闭会话框
    handleClose() {
      this.visible = false
      this.form.content = ''
      this.form.title = ''
    },
    // 删除热词
    handleDel(id) {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(_ => {
        delHotWords(`/${ id }`).then(({ success }) => {
          if (!success) return;
          this.getHotSearch()
          this.$message({ message: '删除成功！', type: 'success' })
        })
      }).catch(_ => {})
    },
    // 排序
    handleMove(id, step) {
      const params = { id, step }
      moveHotWords(params).then(({ success }) => {
        if (!success) return;
        this.getHotSearch()
        this.$message({ message: '排序成功！', type: 'success' })
      })
    }
  },
  watch: {
    hotelInfo(newVal, oldVal) {
      oldVal && newVal.id !== oldVal.id && this.getHotSearch()
    }
  }
}
</script>
<style scoped lang="scss">
.cont {
  ::v-deep .el-form-item__label{ width: 90px }
  ::v-deep .el-form-item__error{ margin-left: 90px }
}
</style>
