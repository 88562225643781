import request from "@/api/service";
import qs from 'qs'

const api = {
   page: '/goods/hotSearch/getPage',
   add: '/goods/hotSearch/add',
   edit: '/goods/hotSearch/edit',
   delete: '/goods/hotSearch/delete',
   move: '/goods/hotSearch/move'
}

export function getPage (params) {
   return request({
      url: api.page,
      method: 'GET',
      params
   })
}

export function addHotWords(params, action) {
   return request({
      url: api[action],
      method: 'POST',
      data: params
   })
}

export function delHotWords(query) {
   return request({
      url: api.delete + query,
      method: 'POST',
   })
}

export function moveHotWords(params) {
   return request({
      url: api.move,
      method: 'POST',
      data: qs.stringify(params)
   })
}
